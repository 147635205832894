/* eslint-disable lines-between-class-members */
import React, {
  useEffect, useState, useCallback,
} from 'react';
import {
  Paper, Typography, Grid, FormControlLabel, Checkbox, Collapse,
} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps } from 'react-router-dom';
import { ArrowLeft } from '@material-ui/icons';
import { SingleSelect } from 'react-select-material-ui';
import { useSnackbar } from 'notistack';
import { setProgressBarStatus as SetProgressBarStatus } from '../../redux/actions/progressBar';
import useStyles from './styles';
import CustomButton from '../CustomButton';
import CustomTextField from '../CustomTextField/CustomTextField';
import {
  beautifyErrors, ValidationErrors, generateLabel, getIntegrationAttributes,
} from '../../utils/helpers';
import { clientCreateSchema } from './clientValidation';
import { BuilderIntegration, BuilderSettings, ClientClass as Client } from '../../types/Client';
import { GET_CLIENT, UPDATE_CLIENT, GET_INTEGRATIONS } from '../../graphql/queries/ClientDetails';
import useFormType from '../../hooks/useFormType';
import { Integration } from '../../types/inputTypes/Integration';
import LassoAttributes from './IntegrationAttributes/LassoAttributes';
import StiAttributes from './IntegrationAttributes/STIAttributes';
import NovihomeAttributes from './IntegrationAttributes/NovihomeAttributes';
import TimezoneSelect from '../TimezoneSelect';
import SalesforceAttributes from './IntegrationAttributes/SalesforceAttributes/SalesforceAttributes';

type Props = {
  setProgressBarStatus: typeof SetProgressBarStatus,
} & RouteComponentProps & { match: { params: { builder_id: string } } };

const ClientDetails: React.FC<Props> = ({ history, match, setProgressBarStatus }) => {
  const classes = useStyles();
  const formType = useFormType(match.params.builder_id);
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading, error, data, refetch,
  } = useQuery<{ builder: Client }>(
    GET_CLIENT,
    {
      variables: { builder_id: parseInt(match.params.builder_id, 10) },
      skip: formType === 'add' || !match.params.builder_id,
      fetchPolicy: 'network-only',
    },
  );

  const { loading: integrationLoading, error: integrationError, data: integrationData } = useQuery<{ integrations: Integration[] }>(
    GET_INTEGRATIONS,
    {
      skip: formType !== 'add',
      fetchPolicy: 'network-only',
    },
  );

  const [updateClient, { loading: updating }] = useMutation<{ updateBuilder: Client }>(
    UPDATE_CLIENT,
  );

  const [client, setClient] = useState<Client>(new Client());
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [builderSettings, setBuilderSettings] = useState<BuilderSettings>({ webflow_name: '' });
  const [integrationAttributes, setIntegrationAttributes] = useState<BuilderIntegration['builderIntegrationAttribute']>([]);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<Client>>({});

  const handleMainContactChange = (username: string) => {
    setClient((prevClient: Client) => ({
      ...prevClient,
      main_contact: username,
    }));
  };
  const handleChangeClient = useCallback(
    ({ target: { name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const booleanFields: (keyof Client)[] = [
        'texting_active', 'LASSO_active', 'STI_active', 'enable_photo_gallery_edits', 'disable_add_property',
        'tour_new_homes_active', 'requires_opt_in', 'TRIPOINTE_active', 'academy_active',
        'bossApi_active', 'allDayAccess_active', 'adjustableTourHours', 'Novihome_active', 'active', 'is_rental', 'is_webflow_code_only', 'high_security_verification_active',
      ];

      if (name === 'webflow_name') {
        const regex = /^[a-zA-Z0-9]+$/;
        const isValid = regex.test(value);

        if (value && !isValid) {
          return;
        }

        setBuilderSettings({ webflow_name: value });

        return;
      }

      if (name) {
        setClient(prevUser => ({
          ...prevUser,
          [name]: booleanFields.includes(name as keyof Client) ? checked : value,
        }));
      }
    },
    [],
  );

  const handleIntegrationSwitch = ({ target: { name, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    const newIntegrations = integrations.map(int => {
      if (int.name === name) {
        return { ...int, isActive: checked };
      }

      return int;
    });

    setIntegrations(newIntegrations);
  };

  const handleIntegrationAttributeChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (!integrationAttributes.length || !integrationAttributes.some(at => at.name === name)) {
      return setIntegrationAttributes([...integrationAttributes, { name, value }]);
    }

    const newAttributes = integrationAttributes.map(attr => {
      if (attr.name === name) {
        return { ...attr, value };
      }

      return attr;
    });

    setIntegrationAttributes(newAttributes);
  };

  const integrationsMap = (builderIntegrations: Client['builderIntegrations']) => {
    if (!builderIntegrations) return [];

    const integrationAttr: Array<any> = [];

    const res = builderIntegrations.map((int: BuilderIntegration) => {
      integrationAttr.push(...int.builderIntegrationAttribute);

      return {
        id: int.integration.id,
        isActive: Boolean(int.isActive),
        name: int.integration.name,
      };
    });

    setIntegrationAttributes(integrationAttr);

    return res;
  };

  useEffect(() => {
    if (data) {
      const { builderIntegrations, builderSettings: settings, ...rest } = data.builder;

      setClient(rest);
      if (builderIntegrations) {
        setIntegrations(integrationsMap(builderIntegrations));
      }

      if (settings) {
        setBuilderSettings(settings);
      }
    }
  }, [loading, data]);

  useEffect(() => {
    if (integrationData) {
      setIntegrations(integrationData.integrations);
    }
  }, [integrationLoading, integrationData]);

  useEffect(() => {
    setProgressBarStatus(loading || updating);
  }, [loading, error, setProgressBarStatus, updating]);

  const processFields = (builder: Partial<Client>) => ({ ...builder, STI_builder_id: Number(builder.STI_builder_id), number_of_licenses: Number(builder.number_of_licenses) });
  const findAttrByName = (name: string) => integrationAttributes
    .find(int => int.name === name)?.value ?? '';

  const validateIntegrations = () => {
    for (const integration of integrations) {
      const attribute = getIntegrationAttributes(integration.name);
      if (!attribute.isBoolean) {
        const attributeValue = findAttrByName(attribute.key);
        if (integration.isActive && !attributeValue) {
          enqueueSnackbar(`${attribute.key} value is required`, { variant: 'error' });
          return false;
        }
      }
    }
    return true;
  };

  const handleSaveClient = () => {
    const isIntegrationsValid = validateIntegrations();
    if (!isIntegrationsValid) {
      return;
    }
    try {
      const { builderIntegrations, builderSettings: settings, ...newClient } = client;
      const uClient = clientCreateSchema.validateSync(newClient, { abortEarly: false });
      setValidationErrors({});
      updateClient({
        variables: {
          builder: {
            ...newClient,
            ...processFields(uClient),
            ...(uClient.STI_builder_id === '' && { STI_builder_id: null }),
          },
          integrations,
          integrationAttributes,
          builderSettings,
        },
      }).then(res => {
        enqueueSnackbar('Success', { variant: 'success' });
        if (formType === 'add') {
          history.push(`/clients/${res?.data?.updateBuilder?.builder_id}`);
        }
        if (formType !== 'add') {
          refetch();
        }
      })
        .catch(() => {
          enqueueSnackbar('An error occurred', { variant: 'error' });
        });
    } catch (errors) {
      setValidationErrors(beautifyErrors<Client>(errors));
    }
  };

  const userOptions = (data && data.builder.users.map(({ username }) => ({
    value: username,
    label: username,
  }))) || [];

  if (data && client.main_contact) {
    const foundClient = userOptions.some(el => el.value === client.main_contact);
    if (!foundClient) {
      userOptions.push({
        value: client.main_contact,
        label: client.main_contact,
      });
    }
  }

  if (loading) return <p>Loading...</p>;
  if (error || integrationError) return <p>Error :(</p>;

  return (
    <div>
      <Grid container justify="space-between" style={{ marginBottom: 25 }}>
        <Grid item>
          <CustomButton variant="white" onClick={() => history.goBack()}>
            <ArrowLeft />
            {' GoBack'}
          </CustomButton>
        </Grid>
        <Grid>
          <CustomButton variant="orange" onClick={handleSaveClient}>
            {formType === 'edit' ? 'Save' : 'Add'}
          </CustomButton>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        {client && (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h3" gutterBottom>
                  {client.name}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>
                    {generateLabel('created_on')}
:
                    {' '}
                  </span>
                  {' '}
                  {client.created_on ? new Date(client.created_on).toLocaleDateString() : ''}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>
                    {generateLabel('created_by')}
:
                    {' '}
                  </span>
                  {' '}
                  {client.created_by}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>
                    {generateLabel('modified_on')}
:
                    {' '}
                  </span>
                  {' '}
                  {client.modified_on ? new Date(client.modified_on).toLocaleDateString() : ''}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>
                    {generateLabel('modified_by')}
:
                    {' '}
                  </span>
                  {' '}
                  {client.modified_by}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>
                    {generateLabel('client_id')}
:
                    {' '}
                  </span>
                  {' '}
                  {client.builder_id}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  margin="normal"
                  required
                  fullWidth
                  label="Client Name"
                  name="name"
                  value={client.name}
                  onChange={handleChangeClient}
                  error={Boolean(validationErrors.name)}
                  helperText={validationErrors.name || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  placeholder=""
                  margin="normal"
                  fullWidth
                  label="Company Code"
                  name="company_code"
                  value={client?.company_code ?? ''}
                  onChange={handleChangeClient}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  placeholder="No spaces or punctuation"
                  margin="normal"
                  fullWidth
                  label="Webflow Name"
                  name="webflow_name"
                  value={builderSettings?.webflow_name ?? ''}
                  onChange={handleChangeClient}
                />
              </Grid>
              <Grid item container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="primary"
                        name="is_rental"
                        checked={Boolean(client.is_rental)}
                        onChange={handleChangeClient}
                      />
                    )}
                    label={generateLabel('Rental Client')}
                    labelPlacement="end"
                    style={{ marginTop: '20px' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="primary"
                        name="active"
                        checked={Boolean(client.active)}
                        onChange={handleChangeClient}
                      />
                    )}
                    label={generateLabel('Active')}
                    labelPlacement="end"
                    style={{ marginTop: '20px' }}
                  />
                </Grid>
              </Grid>
              <Grid item xs>
                <TimezoneSelect
                  fullWidth
                  margin="normal"
                  id="builder_timezone"
                  name="builder_timezone"
                  label="Client Timezone"
                  required
                  defaultTimezone={client?.builder_timezone}
                  value={client.builder_timezone}
                  onChange={handleChangeClient}
                  error={Boolean(validationErrors.builder_timezone)}
                  helperText={(validationErrors.builder_timezone && 'Client Timezone is a required field') || ''}
                />
              </Grid>
              {formType === 'edit' && (
                <Grid item xs={12} sm={6}>
                  <SingleSelect
                    label={generateLabel('Main contact')}
                    options={userOptions}
                    style={{ zIndex: 2 }}
                    onChange={handleMainContactChange}
                    name="main_contact"
                    value={client.main_contact ?? 0}
                    SelectProps={{
                      isCreatable: true,
                      msgNoOptionsMatchFilter: 'No users were found',
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <CustomTextField
                  margin="normal"
                  fullWidth
                  label="Contact Position"
                  name="contact_position"
                  value={client.contact_position || ''}
                  onChange={handleChangeClient}
                  error={Boolean(validationErrors.contact_position)}
                  helperText={validationErrors.contact_position || ''}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CustomTextField
                  margin="normal"
                  fullWidth
                  label={generateLabel('comments')}
                  name="comments"
                  value={client.comments}
                  onChange={handleChangeClient}
                  error={Boolean(validationErrors.comments)}
                  helperText={validationErrors.comments || ''}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomTextField
                  margin="normal"
                  fullWidth
                  label={generateLabel('billing_contract_code')}
                  name="billing_contract_code"
                  value={client.billing_contract_code || ''}
                  onChange={handleChangeClient}
                  error={Boolean(validationErrors.billing_contract_code)}
                  helperText={validationErrors.billing_contract_code || ''}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomTextField
                  margin="normal"
                  fullWidth
                  label={generateLabel('number_of_licenses')}
                  name="number_of_licenses"
                  value={client.number_of_licenses}
                  onChange={handleChangeClient}
                  error={Boolean(validationErrors.number_of_licenses)}
                  helperText={validationErrors.number_of_licenses || ''}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomTextField
                  margin="normal"
                  fullWidth
                  label={generateLabel('lock_lease_fee')}
                  name="lock_lease_fee"
                  value={client.lock_lease_fee}
                  onChange={handleChangeClient}
                  error={Boolean(validationErrors.lock_lease_fee)}
                  helperText={validationErrors.lock_lease_fee || ''}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomTextField
                  margin="normal"
                  fullWidth
                  label={generateLabel('quickbooks_id')}
                  name="quickbooks_id"
                  value={client.quickbooks_id}
                  onChange={handleChangeClient}
                  error={Boolean(validationErrors.quickbooks_id)}
                  helperText={validationErrors.quickbooks_id || ''}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="primary"
                        name="high_security_verification_active"
                        checked={Boolean(client.high_security_verification_active)}
                        onChange={handleChangeClient}
                      />
                    )}
                    label={generateLabel('Use High Security Verification')}
                    labelPlacement="end"
                  />
                </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      name="enable_photo_gallery_edits"
                      checked={Boolean(client.enable_photo_gallery_edits)}
                      onChange={handleChangeClient}
                    />
                  )}
                  label={generateLabel('Enable Photo Gallery Edits')}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      name="disable_add_property"
                      checked={Boolean(client.disable_add_property)}
                      onChange={handleChangeClient}
                    />
                  )}
                  label={generateLabel('Disable add property')}
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            <Collapse in={client.requires_opt_in}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    multiline
                    label="Opt-in notice"
                    name="opt_in_notice"
                    value={client.opt_in_notice || ''}
                    onChange={handleChangeClient}
                    error={Boolean(validationErrors.opt_in_notice)}
                    helperText={validationErrors.opt_in_notice || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    multiline
                    label="Opt-in notice 2"
                    name="opt_in_notice_2"
                    value={client.opt_in_notice_2 || ''}
                    onChange={handleChangeClient}
                    error={Boolean(validationErrors.opt_in_notice_2)}
                    helperText={validationErrors.opt_in_notice_2 || ''}
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="secondary"
                      name="requires_opt_in"
                      checked={Boolean(client.requires_opt_in)}
                      onChange={handleChangeClient}
                    />
                  )}
                  label="Privacy opt-in"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="secondary"
                      name="texting_active"
                      checked={Boolean(client.texting_active)}
                      onChange={handleChangeClient}
                    />
                  )}
                  label={generateLabel('texting_active')}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      name="is_webflow_code_only"
                      checked={Boolean(client.is_webflow_code_only)}
                      onChange={handleChangeClient}
                    />
                  )}
                  label={generateLabel('Webflow Only')}
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="secondary"
                      name="allDayAccess_active"
                      checked={Boolean(client.allDayAccess_active)}
                      onChange={handleChangeClient}
                    />
                  )}
                  label={generateLabel('All Day Access')}
                />
              </Grid>
            </Grid>
            {
              integrations.map(({ name, isActive }) => (
                <React.Fragment key={name}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            color="secondary"
                            name={name}
                            checked={Boolean(isActive)}
                            onChange={handleIntegrationSwitch}
                          />
                        )}
                        label={generateLabel(name)}
                      />
                    </Grid>
                  </Grid>
                  {name === 'Lasso CRM'
                    && (
                      <LassoAttributes
                        lassoId={findAttrByName('Lasso Id')}
                        lassoApikey={findAttrByName('Lasso Apikey')}
                        lassoPrimarySource={findAttrByName('Lasso Primary Source')}
                        lassoSecondarySource={findAttrByName('Lasso Secondary Source')}
                        isActive={isActive}
                        handleChangeClient={handleIntegrationAttributeChange}
                        validationErrors={validationErrors}
                      />
                    )
                  }
                  {
                    name === 'Smart Touch CRM' && (
                      <StiAttributes
                        stiBuilderId={Number(findAttrByName('STI Builder Id'))}
                        // stiBuilderId={client.STI_builder_id}
                        handleChangeClient={handleIntegrationAttributeChange}
                        validationErrors={validationErrors}
                        isActive={isActive}
                      />
                    )
                  }
                  {name === 'Novihome'
                    && (
                      <NovihomeAttributes
                        handleChangeClient={handleIntegrationAttributeChange}
                        novihomeApikey={findAttrByName('Novihome Apikey')}
                        isActive={isActive}
                        validationErrors={validationErrors}
                      />
                    )
                  }
                  {name === 'Salesforce API'
                  && (
                  <SalesforceAttributes
                    isActive={isActive}
                    loginUrl={findAttrByName('Salesforce Login Url')}
                    clientId={findAttrByName('Salesforce Client Id')}
                    clientSecret={findAttrByName('Salesforce Client Secret')}
                    redirectUri={findAttrByName('Salesforce Redirect Uri')}
                    username={findAttrByName('Salesforce Username')}
                    password={findAttrByName('Salesforce Password')}
                    securityToken={findAttrByName('Salesforce Security Token')}
                    validationErrors={validationErrors}
                    handleChangeClient={handleIntegrationAttributeChange}
                  />
                  ) }
                </React.Fragment>
              ))
            }
          </>
        )}
      </Paper>
    </div>
  );
};

export default React.memo(ClientDetails);
